import React, {useState, useEffect} from 'react';
import './modalhomework.css';

const Modal = ({active, setActive, onSave, taskToEdit, existingNames}) => {
  const [data, setData] = useState({
    supervisor: '',
    taskCount: '',
    unknownTaskCount: false,
    systemTest: false,
    branchesWithoutTests: '',
  });

  const [nameError, setNameError] = useState('');
  const [taskCountError, setTaskCountError] = useState('');
  const [branchesError, setBranchesError] = useState('');

  useEffect(() => {
    if (active && taskToEdit) {
      setData({
        supervisor: taskToEdit.supervisor || '',
        taskCount:
          taskToEdit.unknownTaskCount ? '' :
            taskToEdit.numOfTasks ?
              taskToEdit.numOfTasks.toString() : '',
        unknownTaskCount: taskToEdit.unknownTaskCount || false,
        systemTest: taskToEdit.needsCi || false,
        branchesWithoutTests:
          taskToEdit.tasksWithoutTesting && taskToEdit.tasksWithoutTesting.length > 0 ?
            taskToEdit.tasksWithoutTesting.join(', ') : '',
      });
    } else if (!active) {
      setData({
        supervisor: '',
        taskCount: '',
        unknownTaskCount: false,
        systemTest: false,
        branchesWithoutTests: '',
      });
    }
    if (active) {
      setNameError('');
      setTaskCountError('');
      setBranchesError('');
    }
  }, [taskToEdit, active]);

  useEffect(() => {
    if (!data.systemTest || data.unknownTaskCount) {
      setData((prevData) => ({
        ...prevData,
        branchesWithoutTests: '',
      }));
    }
  }, [data.systemTest, data.unknownTaskCount]);

  const handleChange = (e) => {
    const {name, value, type, checked} = e.target;
    const updatedValue = type === 'checkbox' ? checked : value.toString();

    if (name === 'branchesWithoutTests') {
      validateBranches(updatedValue);
    }
    const updatedData = {
      ...data,
      [name]: updatedValue,
    };
    if (name === 'unknownTaskCount' && checked) {
      updatedData.taskCount = '';
      updatedData.systemTest = false;
      updatedData.branchesWithoutTests = '';
      setTaskCountError('');
      setBranchesError('');
    } else if (name === 'systemTest' && !checked) {
      updatedData.branchesWithoutTests = '-';
      setBranchesError('');
    } else if (name === 'systemTest' && checked) {
      if (data.branchesWithoutTests === '-') {
        updatedData.branchesWithoutTests = '';
      }
    }

    setData(updatedData);

    if (name === 'supervisor') {
      validateName(value);
    } else if (name === 'taskCount') {
      if (!data.unknownTaskCount) {
        validateTaskCount(value);
      }
      if (data.systemTest && data.branchesWithoutTests.trim() !== '') {
        validateBranches(updatedData.branchesWithoutTests, value);
      }
    } else if (name === 'branchesWithoutTests') {
      if (data.systemTest) {
        validateBranches(value, updatedData.taskCount);
      }
    } else if (name === 'systemTest' && !checked) {
      setBranchesError('');
      if (data.unknownTaskCount) {
        setTaskCountError('');
      }
    } else if (name === 'unknownTaskCount') {
      if (checked) {
        setTaskCountError('');
        setData({
          ...updatedData,
          taskCount: '',
        });
      } else if (data.taskCount.trim() === '') {
        setTaskCountError('Количество задач обязательно для заполнения.');
      }
    }
  };

  const validateBranches = (branches, taskCount) => {
    if (!data.systemTest || branches.trim() === '') {
      setBranchesError('');
      return;
    }

    const cleanedBranches = branches.replace(/\s+/g, '');
    const regex = /^(\d+)(,\d+)*$/;
    const branchesArray = cleanedBranches.split(',').map((branch) => parseInt(branch, 10));

    if (!regex.test(cleanedBranches)) {
      setBranchesError('Некорректный список номеров веток. Используйте целые числа, разделенные запятой.');
      return;
    }
    const hasDuplicates = branchesArray.some(
        (branch, index) => branchesArray.indexOf(branch) !== index
    );
    if (hasDuplicates) {
      setBranchesError('Номера веток должны быть уникальными.');
      return;
    }

    if (!regex.test(cleanedBranches)) {
      setBranchesError('Некорректный список номеров веток. Используйте целые числа, разделенные запятой.');
    } else if (branchesArray.some((branch) => branch < 1 || branch > parseInt(taskCount, 10))) {
      setBranchesError('Используется некорректный номер ветки.');
    } else {
      setBranchesError('');
    }
  };

  const validateTaskCount = (count) => {
    if (data.unknownTaskCount) {
      setTaskCountError('');
      return;
    }
    if (count.trim() === '') {
      setTaskCountError('Это поле обязательно для заполнения.');
      return;
    }
    if (/[^0-9]/.test(count)) {
      setTaskCountError('Разрешены только целые числа.');
      return;
    }
    if (/^0\d+/.test(count)) {
      setTaskCountError('Количество должно быть в диапазоне от 1 до 100.');
      return;
    }
    const parsedCount = parseInt(count, 10);
    if (isNaN(parsedCount) || !Number.isInteger(parsedCount)) {
      setTaskCountError('Разрешены только целые числа.');
    } else if (parsedCount < 1 || parsedCount > 100) {
      setTaskCountError('Количество должно быть в диапазоне от 1 до 100.');
    } else {
      setTaskCountError('');
    }
  };

  const validateName = (name) => {
    const regex = /^[a-zA-Z0-9_.-]+$/;
    if (name.trim() === '') {
      setNameError('Это поле обязательно для заполнения.');
    } else if (name.length > 100) {
      setNameError('Максимальная длина поля — 100 символов.');
    } else if (!regex.test(name)) {
      setNameError('Допустимы только латинские буквы, цифры, и символы: `.`, `-` и `_`.');
    } else if (existingNames.includes(name)) {
      setNameError('Название должно быть уникальным.');
    } else {
      setNameError('');
    }
  };

  const handleSave = () => {
    let isValid = true;

    if (data.supervisor.trim() === '') {
      setNameError('Это поле обязательно для заполнения.');
      isValid = false;
    } else {
      validateName(data.supervisor);
      if (nameError) isValid = false;
    }

    if (!data.unknownTaskCount && data.taskCount.trim() === '') {
      setTaskCountError('Это поле обязательно для заполнения.');
      isValid = false;
    } else if (!data.unknownTaskCount) {
      validateTaskCount(data.taskCount);
      if (taskCountError) isValid = false;
    }

    if (data.systemTest && data.branchesWithoutTests.trim() !== '') {
      validateBranches(data.branchesWithoutTests, data.taskCount);
      if (branchesError) isValid = false;
    }

    if (nameError || taskCountError || branchesError || !isValid) {
      console.error('Failed to complete the task: there are errors in the data.', {
        nameError,
        taskCountError,
        branchesError,
      });
      return;
    }

    const taskData = {
      supervisor: data.supervisor,
      numOfTasks: parseInt(data.taskCount, 10),
      needsCi: data.systemTest,
      tasksWithoutTesting: data.systemTest && data.branchesWithoutTests.trim() !== '' ?
        data.branchesWithoutTests
            .split(',')
            .map((branch) => parseInt(branch.trim(), 10))
            .filter((branch) => !isNaN(branch)) :
        [],
      ciFileId: data.ciFileId ? parseInt(data.ciFileId, 10) : null,
      dockerfileId: data.dockerfileId ? parseInt(data.dockerfileId, 10) : null,
      unknownTaskCount: data.unknownTaskCount,
    };

    onSave(taskData);
    setData({
      supervisor: '',
      taskCount: '',
      unknownTaskCount: false,
      systemTest: false,
      branchesWithoutTests: '',
    });
    setActive(false);
  };

  const handleBlur = (e) => {
    const {name, value} = e.target;

    if (value.trim() === '') {
      switch (name) {
        case 'supervisor':
          setNameError('Это поле обязательно для заполнения.');
          break;
        case 'taskCount':
          if (!data.unknownTaskCount) {
            setTaskCountError('Это поле обязательно для заполнения.');
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className=
      {active ? `modal active` : 'modal'}
    onClick={setActive}>
      <div className={`modal__content ${data.systemTest && !data.unknownTaskCount ? 'extra-height' : ''} ${
        nameError || taskCountError || branchesError ? 'small' : ''
      }`}
      onClick={(e) => e.stopPropagation()}>
        <div className="modal-form">
          <h4 className="modal__title"> Новое домашнее задание </h4>
          <div className="form-field">
            <div className="d-flex align-items-center">
              <h4>Название</h4>
              <span className="form-star">*</span>
            </div>
            <input
              className={`modal-input ${nameError ? 'error' : ''}`}
              type="text"
              name="supervisor"
              value={data.supervisor}
              onChange={handleChange}
              placeholder="mapreduce"
              onBlur={handleBlur}
            />
            {nameError && <p className="error-message"
              style={{color: 'red', fontSize: '14px'}}>{nameError}</p>}
          </div>
          <div className="form-field">
            <div className="d-flex align-items-center">
              <h4>Количество задач</h4>
              <span className="form-star">*</span>
            </div>
            <input
              className={`modal-input ${taskCountError ? 'error' : ''}`}
              type="text"
              name="taskCount"
              value={data.taskCount}
              onChange={handleChange}
              placeholder="Например, 5"
              disabled={data.unknownTaskCount}
              onBlur={handleBlur}
            />
            {taskCountError &&
              <p className="error-message" style={{color: 'red', fontSize: '14px'}}>
                {taskCountError}
              </p>
            }
            <div className="checkbox d-flex align-items-center">
              <input
                id="unknownTaskCount"
                className="checkbox__input"
                type="checkbox"
                name="unknownTaskCount"
                checked={data.unknownTaskCount}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="unknownTaskCount"
                className="checkbox__text">
                Я не знаю количество задач
              </label>
            </div>
            <div className="system-test d-flex align-items-center">
              <div className="d-flex align-items-center">
                <h4>Система тестирования:</h4>
              </div>
              <div className="toggle">
                <input
                  className="toggle__input"
                  type="checkbox"
                  id="check"
                  name="systemTest"
                  checked={data.systemTest}
                  onChange={(e) => {
                    handleChange(e);
                    if (!e.target.checked || data.unknownTaskCount) {
                      setData((prevData) => ({
                        ...prevData,
                        branchesWithoutTests: '',
                      }));
                    }
                  }}
                />
                <label htmlFor="check" className="toggle__button"></label>
              </div>
            </div>
            {data.systemTest && !data.unknownTaskCount && (
              <div className="form-field">
                <div className="d-flex align-items-center">
                  <h4>Номера веток без тестов</h4>
                </div>
                <input
                  className={`modal-input ${branchesError ? 'error' : ''}`}
                  type="text"
                  name="branchesWithoutTests"
                  value={data.branchesWithoutTests}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.checked) {
                      setData((prevData) => ({
                        ...prevData,
                        branchesWithoutTests: '',
                        taskCount: '',
                      }));
                    }
                  }}
                  placeholder="Начните вводить 1, 2, 3"
                  onBlur={handleBlur}
                />
                {branchesError &&
                  <p className="error-message" style={{color: 'red', fontSize: '14px'}}>
                    {branchesError}
                  </p>
                }
              </div>
            )}
          </div>
          <button type="button" className="modal__button" onClick={handleSave}>
            {taskToEdit ? 'Сохранить' : 'Добавить'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
