// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuccessPage {
    padding: 150px 0 200px;
}

.success {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    margin-top: 20px;
}

.success__title {
    font-weight: 400;
    color: #0A142F;
    margin-bottom: 40px;
    font-size: 28px;
}

.success .success__btn {
    padding: 10px 1px;
    width: 360px;
    margin-bottom: -20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/success/successPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":[".SuccessPage {\n    padding: 150px 0 200px;\n}\n\n.success {\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    font-family: 'Lato', sans-serif;\n    margin-top: 20px;\n}\n\n.success__title {\n    font-weight: 400;\n    color: #0A142F;\n    margin-bottom: 40px;\n    font-size: 28px;\n}\n\n.success .success__btn {\n    padding: 10px 1px;\n    width: 360px;\n    margin-bottom: -20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
