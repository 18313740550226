import React, {createContext, useEffect, useState} from 'react';
import {backendUrl} from '../constant/AppConstant';

/**
 * User data holder to embed in context
 */
export class UserData {
  /**
   * Full constructor
   * @param {string} firstName User first name
   * @param {string} lastName User last name
   * @param {string} patronymic User patronymic
   * @param {number} userType User userType
   * @param {boolean} approved User approved
   */
  constructor(firstName = '', lastName = '', patronymic = '', userType, approved) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.patronymic = patronymic;
    this.userType = userType;
    this.approved = approved;
  }
}

const initialUserData = new UserData();

export const AuthContext = createContext({
  userData: initialUserData,
  setUserData: (value) => {},
});

export const AuthProvider = ({children}) => {
  const [userData, setUserData] = useState(initialUserData);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${backendUrl}/session/getUserInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const user = data.result;
      console.log(user);

      if (user) {
        setUserData(new UserData(user.firstName, user.lastName, user.patronymic, user.userType, user.approved));
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    if (!userData.firstName) {
      fetchUserData();
    }
  }, [userData]);

  return (
    <AuthContext.Provider value={{userData, setUserData}}>
      {children}
    </AuthContext.Provider>
  );
};
