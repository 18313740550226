import React from 'react';

import './successPage.css';
import {Link} from 'react-router-dom';

const SuccessPage = ({fullName}) => {
  return (
    <div className="SuccessPage" id="SuccessPage">
      <div className="container">
        <div className="success">
          <h1 className="success__title">
            Вы успешно зарегистрировали курс {fullName}
          </h1>
          <Link to="/courses/register" className="form-register success__btn">
            Зарегистрировать еще один курс
          </Link>
          <Link to="/courses/register/config" className="form-register success__btn">
            Скачать конфиги
          </Link>
          <Link to="/" className="form-register success__btn">
            На главную
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
