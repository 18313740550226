import React, {useState} from 'react';
import uploadIcon from '../../assets/upload.svg';
import deleteIcon from '../../assets/delete.svg';
import styles from './AttachmentUpload.module.css';
import useAttachmentUpload from './useAttachmentUpload';
import calculateMd5 from '../../utils/calculateMd5';

const AttachmentUpload = ({onUploadSuccess, onRemoveSuccess}) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const {uploadAttachment, removeAttachment} = useAttachmentUpload();

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setIsUploading(true);
      try {
        const md5Checksum = await calculateMd5(selectedFile);
        const response = await uploadAttachment(selectedFile, md5Checksum);
        console.log('Uploaded file:', response);
        setFile(response);
        setIsUploading(false);
        if (onUploadSuccess) {
          onUploadSuccess(response);
        }
      } catch (error) {
        console.error('Error while uploading file:', error);
        setIsUploading(false);
      }
    }
  };

  const handleRemove = async () => {
    console.log('handleRemove called');
    if (file && file.id) {
      try {
        console.log('Calling removeAttachment with id:', file.id);
        await removeAttachment(file.id);
        setFile(null);
        if (onRemoveSuccess) {
          onRemoveSuccess();
        }
      } catch (error) {
        console.error('Error when deleting file:', error);
      }
    }
  };

  return (
    <div className={styles.attachmentUpload}>
      <div>
        {file ? (
          <div className="d-flex align-items-center">
            <span>{file.name}</span>
            <button type="button" onClick={handleRemove} className={styles.deleteButton}>
              <img src={deleteIcon} alt="Удалить" />
            </button>
          </div>
        ) : (
          <label>
            <input type="file" onChange={handleFileChange} style={{display: 'none'}} />
            <img src={uploadIcon} className={styles.uploadButton} alt="Загрузить" />
            {isUploading ? 'Загрузка...' : ''}
          </label>
        )}
      </div>
    </div>
  );
};

export default AttachmentUpload;
