import React from 'react';
import '../../pages/registration/courseRegistration.css';
import PropTypes from 'prop-types';

const TextField = ({type, id, name, value, onChange, placeholder, error, onBlur}) => {
  return (
    <>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
        className="form-input"
      />
      {error && <p className="form-error">{error}</p>}
    </>
  );
};

TextField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
};

export default TextField;
