// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background: #0A142F;
    padding: 12px 0;
    color: #fff;
    margin-bottom: 20px;
}

.studoko__img {
    width: 50px;
    height: 50px;
}

.navbar__login {
    font-weight: 500;
}

.navbar__name {
    margin-left: 20px;
    width: 131px;
    height: 32px;
}

.log-in, .log-out {
    cursor: pointer;
    outline: none;
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    font-weight: 400;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    margin-left: 35px;
    font-family: 'Lato', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,6BAA6B;IAC7B,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,+BAA+B;AACnC","sourcesContent":[".header {\n    background: #0A142F;\n    padding: 12px 0;\n    color: #fff;\n    margin-bottom: 20px;\n}\n\n.studoko__img {\n    width: 50px;\n    height: 50px;\n}\n\n.navbar__login {\n    font-weight: 500;\n}\n\n.navbar__name {\n    margin-left: 20px;\n    width: 131px;\n    height: 32px;\n}\n\n.log-in, .log-out {\n    cursor: pointer;\n    outline: none;\n    background: transparent;\n    border: none;\n    border-bottom: 1px solid #fff;\n    font-weight: 400;\n    width: 48px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #fff;\n    font-size: 16px;\n    margin-left: 35px;\n    font-family: 'Lato', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
