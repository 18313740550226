// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AttachmentUpload_attachmentUpload__H1i6W {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.AttachmentUpload_uploadedFile__QLBnl img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.AttachmentUpload_uploadButton__fbBHR {
    color: #fff;
    background: #0A142F;
    border-radius: 8px;
    padding: 11px 21px;
    border: none;
    cursor: pointer;
    display: block;
    margin: 10px auto 0;
}

.AttachmentUpload_deleteButton__bW1Kz {
    background-color: #0A142F;
    border: none;
    border-radius: 4px;
    width: 33px;
    height: 33px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
    margin-left: 15px;
    white-space: nowrap;
}

`, "",{"version":3,"sources":["webpack://./src/components/attachmentUpload/AttachmentUpload.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".attachmentUpload {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.uploadedFile img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.uploadButton {\n    color: #fff;\n    background: #0A142F;\n    border-radius: 8px;\n    padding: 11px 21px;\n    border: none;\n    cursor: pointer;\n    display: block;\n    margin: 10px auto 0;\n}\n\n.deleteButton {\n    background-color: #0A142F;\n    border: none;\n    border-radius: 4px;\n    width: 33px;\n    height: 33px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    max-width: none;\n    margin-left: 15px;\n    white-space: nowrap;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attachmentUpload": `AttachmentUpload_attachmentUpload__H1i6W`,
	"uploadedFile": `AttachmentUpload_uploadedFile__QLBnl`,
	"uploadButton": `AttachmentUpload_uploadButton__fbBHR`,
	"deleteButton": `AttachmentUpload_deleteButton__bW1Kz`
};
export default ___CSS_LOADER_EXPORT___;
