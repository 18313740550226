import {backendUrl} from '../../constant/AppConstant';

const useAttachmentUpload = () => {
  const uploadAttachment = async (file, md5) => {
    const formData = new FormData();
    formData.append('md5', md5);
    formData.append('archive', file);

    const response = await fetch(`${backendUrl}/course/attachment/postArchive`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('File upload error');
    }
    const res = await response.json();
    const id = res.result ?? null;
    if (!id) {
      console.warn('The server did not return an id for the uploaded file.');
    } else {
      console.log('Uploaded file ID:', id);
    }
    return {id, name: file.name};
  };

  const removeAttachment = async (id) => {
    if (!id) {
      console.warn('No id to delete file');
      return;
    }
    try {
      console.log('removeAttachment called with id:', id);
      const response = await fetch(`${backendUrl}/course/attachment/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id: Number(id)}),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error deleting file:', errorText);
        throw new Error('Error deleting file');
      } else {
        console.log('File successfully deleted');
      }
    } catch (error) {
      console.error('Error in removeAttachment:', error);
      throw error;
    }
  };

  return {
    uploadAttachment,
    removeAttachment,
  };
};

export default useAttachmentUpload;
