// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
}

.error-page h1 {
    font-family: 'Lato', sans-serif;
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 30px;
}

.error-page p {
    text-align: center;
    color: #757272;
    font-size: 20px;
    line-height: 27px;
}

.error__btn {
    text-align: center;
    border: none;
    cursor: pointer;
    color: #fff;
    background: #0A142F;
    border-radius: 9px;
    display: block;
    margin: 40px auto 0;
    padding: 14px 140px;
}`, "",{"version":3,"sources":["webpack://./src/pages/errorPage/errorPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".error-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 100px 0;\n}\n\n.error-page h1 {\n    font-family: 'Lato', sans-serif;\n    font-size: 45px;\n    font-weight: 600;\n    margin-bottom: 30px;\n}\n\n.error-page p {\n    text-align: center;\n    color: #757272;\n    font-size: 20px;\n    line-height: 27px;\n}\n\n.error__btn {\n    text-align: center;\n    border: none;\n    cursor: pointer;\n    color: #fff;\n    background: #0A142F;\n    border-radius: 9px;\n    display: block;\n    margin: 40px auto 0;\n    padding: 14px 140px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
