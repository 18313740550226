import {React, useRef, useCallback, useEffect, useMemo, useState} from 'react';

import edit from '../../assets/edit.svg';
import del from '../../assets/delete.svg';
import plus from '../../assets/plus.svg';
import './courseRegistration.css';
import Modal from '../../modal/ModalHomework';
import TextField from '../../components/textField/textField';
import {validator} from '../../utils/validator';
import {backendUrl} from '../../constant/AppConstant';
import AttachmentUpload from '../../components/attachmentUpload/AttachmentUpload';
import CourseSeminarians from '../../components/courseSeminarians/CourseSeminarians';
import CourseAssistants from '../../components/courseAssistants/CourseAssistants';
import CourseSupervisors from '../../components/courseSupervisors/CourseSupervisors';
import {useNavigate} from 'react-router-dom';

const CourseRegistration = ({setFullName}) => {
  const [touchedFields] = useState({});
  const [modalActive, setModalActive] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate();
  const [seminarianIds, setSeminarianIds] = useState([]);
  const [assistantIds, setAssistantIds] = useState([]);
  const [tasksError, setTasksError] = useState('');
  const [supervisorError, setSupervisorError] = useState('');

  const nameRef = useRef(null);
  const shortNameRef = useRef(null);
  const supervisorRef = useRef(null);
  const spreadsheetUrlRef = useRef(null);
  const serversRef = useRef(null);
  const tasksRef = useRef(null);

  const handleAddTask = (taskData) => {
    setTasks((prevTasks) => [...prevTasks, {...taskData, id: Date.now()}]);
  };

  const handleDeleteTask = (taskId) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };
  const handleEditTask = (taskId) => {
    const taskIndex = tasks.findIndex((task) => task.id === taskId);
    setEditIndex(taskIndex);
    setModalActive(true);
  };

  const handleSaveTask = (index, updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((task, i) =>
        (i === index ? {...task, ...updatedTask} : task)));
    setModalActive(false);
    setEditIndex(null);
  };

  const closeModal = () => {
    setEditIndex(null);
    setModalActive(false);
  };

  const [selectedSupervisorId, setSelectedSupervisorId] = useState(null);
  const archiveIdRef = useRef(null);
  const [data, setData] = useState({
    name: '',
    shortName: '',
    year: new Date().getFullYear(),
    isAutumn: false,
    spreadsheetUrl: '',
    sheetId: '',
    sheetNum: '',
    headId: selectedSupervisorId,
    defaultCiId: '',
    defaultDockerfileId: '',
    id: '',
    table: '',
  });

  const [errors, setErrors] = useState({});
  const handleChange = ({target}) => {
    const {name, value} = target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Это поле обязательно для заполнения.',
      }));
    } else {
      const fieldErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors[name] || '',
      }));
    }
  };

  const validatorConfig = useMemo(() => ({
    name: {
      isRequired: {
        message: 'Это поле обязательно для заполнения.',
      },
      isFormatValid: {
        message:
          'Допустимы только буквы, цифры, пробелы и символы: `-`, `:`, `;`, `_`, `+`, `,`, `.`.',
      },
      maxLength: {
        value: 255,
        message: 'Максимальная длина поля — 255 символов.',
      },
    },
    shortName: {
      isRequired: {
        message: 'Это поле обязательно для заполнения.',
      },
      isIdValid: {
        message: 'Допустимы только латинские буквы, цифры, и символы: `.`, `-`, `_`.',
      },
      maxLength: {
        value: 100,
        message: 'Максимальная длина поля — 100 символов.',
      },
    },
    spreadsheetUrl: {
      isRequired: {
        message: 'Это поле обязательно для заполнения.',
      },
      isTableValid: {
        message:
          'Приложите ссылку на Google таблицу. Ссылка должна начинаться на `https://docs.google.com/spreadsheets/`.',
      },
      isNotEqualToDefault: {
        defaultValue: 'https://docs.google.com/spreadsheets/',
        message: 'Некорректная ссылка на Google таблицу.',
      },
      maxLength: {
        value: 1024,
        message: 'Максимальная длина поля — 1024 символа.',
      },
    },
    branchesWithoutTests: {
      isBranchNumberValid: {
        message: 'Номера веток должны быть положительными целыми числами, разделенными запятыми',
        taskCount: 100,
      },
    },
  }), []);

  const validate = useCallback((dataToValidate = data) => {
    const validationErrors = {};

    Object.keys(validatorConfig).forEach((field) => {
      const fieldConfig = validatorConfig[field];
      const fieldData = dataToValidate[field];

      if (fieldConfig.isRequired && (!fieldData || fieldData.trim() === '')) {
        validationErrors[field] = fieldConfig.isRequired.message;
      } else if (fieldData) {
        const fieldErrors = validator({[field]: fieldData}, {[field]: fieldConfig});
        if (fieldErrors[field]) {
          validationErrors[field] = fieldErrors[field];
        }
      }
    });

    if (!supervisorRef.current || !supervisorRef.current.querySelector('textarea').value) {
      validationErrors.supervisor = 'Это поле обязательно для заполнения.';
    }
    console.log('Validation Errors:', validationErrors);
    setErrors(validationErrors);
    return validationErrors;
  }, [data, validatorConfig]);

  const [serversTouched, setServersTouched] = useState(false);
  const [serversError, setServersError] = useState('');
  const [servers, setServers] = useState([]);
  const [selectedServers, setSelectedServers] = useState([]);

  useEffect(() => {
    fetchServers();
  }, []);
  const fetchServers = async () => {
    try {
      const response = await fetch(`${backendUrl}/course/getServerList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log(data);
      setServers(data.result.servers || []);
    } catch (error) {
      console.error('Error loading servers:', error);
    }
  };

  const handleCheckboxChange = (id) => {
    setServersTouched(true);
    const updatedSelectedServers = selectedServers.includes(id) ?
      selectedServers.filter((serverId) => serverId !== id) :
      [...selectedServers, id];
    setSelectedServers(updatedSelectedServers);

    if (updatedSelectedServers.length === 0) {
      setServersError('Выберите хотя бы один сервер');
    } else {
      setServersError('');
    }
  };

  const checkIfAtLeastOneChecked = () => {
    const checkboxes = document.querySelectorAll('.server-list input[type="checkbox"]');
    return Array.from(checkboxes).some((checkbox) => checkbox.checked);
  };

  const clearSupervisorError = () => {
    setSupervisorError('');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('archiveId:', archiveIdRef.current);
    const validationErrors = validate();
    const isValid = Object.keys(validationErrors).length === 0;
    const isServersChecked = checkIfAtLeastOneChecked();
    if (!isServersChecked) {
      setServersError('Выберите хотя бы один сервер.');
      setServersTouched(true);
    } else {
      setServersError('');
    }
    if (tasks.length === 0) {
      setTasksError('Добавьте хотя бы одно задание.');
    } else {
      setTasksError('');
    }
    setSupervisorError(validationErrors.supervisor || '');
    if (!isValid || !isServersChecked || tasks.length === 0) {
      if (validationErrors.name) {
        nameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        nameRef.current.focus();
      } else if (validationErrors.shortName) {
        shortNameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        shortNameRef.current.focus();
      } else if (validationErrors.supervisor && supervisorRef.current) {
        supervisorRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      } else if (validationErrors.spreadsheetUrl) {
        spreadsheetUrlRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        spreadsheetUrlRef.current.focus();
      } else if (tasksError) {
        tasksRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      } else if (serversError) {
        serversRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
      return;
    }

    const requestBody = {
      name: data.name,
      shortName: data.shortName,
      year: data.year,
      isAutumn: data.isAutumn,
      spreadsheetUrl: data.spreadsheetUrl,
      sheetId: data.sheetId,
      sheetNum: parseInt(data.sheetNum, 10),
      headId: selectedSupervisorId,
      seminarianIds: seminarianIds,
      assistantIds: assistantIds,
      homeworks: tasks.map((task) => ({
        name: task.supervisor,
        numOfTasks: task.unknownTaskCount ? null : task.numOfTasks,
        needsCi: task.needsCi,
        tasksWithoutTesting: task.tasksWithoutTesting,
        ciFileId: task.ciFileId || null,
        dockerfileId: task.dockerfileId || null,
      })),
      serverIds: Array.from(document.querySelectorAll('.server-list input[type="checkbox"]:checked'))
          .map((checkbox) => parseInt(checkbox.value, 10)),
      defaultCiId: data.defaultCiId ? parseInt(data.defaultCiId, 10) : null,
      defaultDockerfileId: data.defaultDockerfileId ? parseInt(data.defaultDockerfileId, 10) : null,
      archiveId: archiveIdRef.current ? parseInt(archiveIdRef.current, 10) : null,
    };
    setFullName(requestBody.name);

    console.log('requestBody:', requestBody);

    try {
      const response = await fetch(`${backendUrl}/course/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        navigate('/courses/register/success');
      } else {
        console.error('Error registering course');
      }
    } catch (error) {
      console.log('Error sending data to server:', error);
    }
  };

  const extractSheetIdFromUrl = (url) => {
    const idMatch = url.match(/\/d\/([a-zA-Z0-9-_]+)/);
    return idMatch ? idMatch[1] : '';
  };

  const extractSheetNumFromUrl = (url) => {
    const gidMatch = url.match(/gid=(\d+)/);
    return gidMatch ? parseInt(gidMatch[1], 10) : 0;
  };

  const handleSpreadsheetUrlChange = (event) => {
    handleChange(event);
    const value = event.target.value;
    setData((prevData) => ({
      ...prevData,
      spreadsheetUrl: value,
      sheetId: extractSheetIdFromUrl(value),
      sheetNum: extractSheetNumFromUrl(value),
    }));
  };

  useEffect(() => {
    if (tasks.length > 0 && tasksError) {
      setTasksError('');
    }
  }, [tasks, tasksError]);

  const handleBlur = ({target: {name, value}}) => {
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Это поле обязательно для заполнения.',
      }));
    } else {
      const fieldErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors[name] || '',
      }));
    }
  };

  useEffect(() => {
    if (Object.keys(touchedFields).length > 0) {
      validate();
    }
  }, [data, touchedFields, validate]);

  const validateField = (name, value) => {
    return validator({[name]: value}, validatorConfig);
  };
  return (
    <div className="registration" id="registration">
      <div className="container">
        <div className="register-form-container">
          <h1 className="form-title">
            Регистрация курса
          </h1>
          <form onSubmit={handleSubmit} className="form-filds">
            <div className="form-field" ref={nameRef}>
              <div className="d-flex align-items-center">
                <h4>Полное название курса</h4>
                <span className="form-star">*</span>
              </div>
              <TextField
                type="text"
                id="name"
                name="name"
                value={data.name}
                onChange={handleChange}
                placeholder="Параллельные алгоритмы"
                error={errors.name}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field" ref={shortNameRef}>
              <div className="d-flex align-items-center">
                <h4>Идентификатор курса</h4>
                <span className="form-star">*</span>
              </div>
              <TextField
                type="text"
                id="shortName"
                name="shortName"
                value={data.shortName}
                onChange={handleChange}
                placeholder="my-iDENTifier.123"
                error={errors.shortName}
                onBlur={handleBlur}
              />
            </div>
            <CourseSupervisors
              ref={supervisorRef}
              setSelectedSupervisorId={setSelectedSupervisorId}
              error={supervisorError}
              onErrorClear={clearSupervisorError}
            />
            <CourseSeminarians
              selectedSupervisorId={selectedSupervisorId}
              onSelectionChange={setSeminarianIds}
            />
            <CourseAssistants
              selectedSupervisorId={selectedSupervisorId}
              selectedSeminarians={seminarianIds}
              onSelectionChange={setAssistantIds}
            />
            <div className="form-field" ref={spreadsheetUrlRef}>
              <div className="d-flex align-items-center">
                <h4>Ссылка на таблицу курса</h4>
                <span className="form-star">*</span>
              </div>
              <div className="form-indent">
                <p>В таблице должно быть минимум 2 вкладки:</p>
                <ul>
                  <li>Список студентов</li>
                  <li>Оценки студентов</li>
                </ul>
                <p>К таблице нужно выдать доступ на 2 email'а:</p>
                <ul>
                  <li>servers@atp-fivt.org</li>
                  <li>gsheets@automation-robot-1.iam.gserviceaccount.com</li>
                </ul>
              </div>
              <TextField
                type="text"
                id="spreadsheetUrl"
                name="spreadsheetUrl"
                value={data.spreadsheetUrl}
                onChange={handleSpreadsheetUrlChange}
                placeholder="https://docs.google.com/spreadsheets/d/example"
                error={errors.spreadsheetUrl}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field" ref={tasksRef}>
              <div className="d-flex align-items-center">
                <h4>Конфигурирование домашних заданий</h4>
                <span className="form-star">*</span>
              </div>
              {tasks.length >= 0 && (
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Название</th>
                        <th>Количество задач</th>
                        <th>Тестирование</th>
                        <th>№ веток без тестов</th>
                        <th>Действия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasks.map((task) => (
                        <tr key={task.id}>
                          <td>{task.supervisor}</td>
                          <td>{task.unknownTaskCount ? 'N/A' : task.numOfTasks !== null ?
                            task.numOfTasks : 'N/A'}</td>
                          <td>{task.needsCi ? 'Да' : 'Нет'}</td>
                          <td>
                            {task.tasksWithoutTesting && task.tasksWithoutTesting.length > 0 ?
                              task.tasksWithoutTesting.join(', ') : '-'}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <button type="button" className="edit"
                                onClick={() => handleEditTask(task.id)}>
                                <img src={edit} alt="Редактировать"/>
                              </button>
                              <button type="button" className="delete"
                                onClick={() => handleDeleteTask(task.id)}>
                                <img src={del} alt="Удалить"/>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <button type="button" className="form-plus form-modal"
                onClick={() => {
                  setEditIndex(null);
                  setModalActive(true);
                }}>
                <img src={plus} alt="Plus"/>
              </button>
              {tasksError && <p className="form-error">{tasksError}</p>}
              <Modal
                active={modalActive}
                setActive={closeModal}
                onAdd={handleAddTask}
                onSave={(taskData) => {
                  if (editIndex !== null) {
                    handleSaveTask(editIndex, taskData);
                  } else {
                    handleAddTask(taskData);
                  }
                }}
                taskToEdit={editIndex !== null ? tasks[editIndex] : null}
                existingNames={tasks.map((task) => task.supervisor)}
              />
            </div>
            <div className="form-field form-margin" ref={serversRef}>
              <div className="d-flex align-items-center">
                <h4>Необходимые сервера</h4>
                <span className="form-star">*</span>
              </div>
              <p> Отметьте галочкой нужные для курса сервера.
                Характеристики каждого<br/> сервера - в кружочке с вопросом.
              </p>
              <ul className="server-list">
                {servers.map((server) => (
                  <li key={server.id} className="server-item d-flex align-items-center">
                    <label className="server-name">
                      <input
                        type="checkbox"
                        value={server.id}
                        onChange={() => handleCheckboxChange(server.id)}
                        checked={selectedServers.includes(server.id)}
                      />
                    </label>
                    <p>{server.name}</p>
                    <span
                      className=
                        {modalActive ? 'server-characteristics none' :
                          'server-characteristics'}
                    >
                      ?
                    </span>
                  </li>
                ))}
              </ul>
              {serversTouched && serversError && <p className="form-error">{serversError}</p>}
            </div>
            <div className="form-field">
              <div>
                <h4>Специализированный CI и Dockerfile</h4>
              </div>
              <div className="form-indent">
                <p>Прикрепите архив из следующих файлов:</p>
                <ul className="no-flex">
                  <li>Если нужен CI, то добавьте gitlab-ci.yml файлы для
                    каждого<br/> репозитория;
                  </li>
                  <li>Если нужен специальный Docker контейнер, то добавьте
                    исходный<br/> Dockerfile с нужными данными.
                  </li>
                </ul>
              </div>
              <AttachmentUpload
                onUploadSuccess={(file) => {
                  console.log('file uploaded', file);
                  archiveIdRef.current = file.id;
                }}
                onRemoveSuccess={() => {
                  console.log('File removed');
                  archiveIdRef.current = null;
                }}
              />
            </div>
            <button type="submit" className="form-register">
              Зарегистрировать курс
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CourseRegistration;
