import './modalthanks.css';
import logo from '../assets/exit.svg';
import React from 'react';

const ModalThanks =({active, setActive})=> {
  return (
    <div className={active ? 'modal_background active' : 'modal_background' } onClick={() => setActive(!active)}>
      <div className="modal_window" onClick={(e) => e.stopPropagation()}>
        <div className="modal_content">
          <div className="modal_block">
            <h1>Благодарности</h1>
            <p>Спасибо студентам, которые разрабатывали приложение СтудОко:</p>
            <ul className="modal-list">
              <li>Артём Фартыгин</li>
              <li>Александр Кошелев</li>
              <li>Алексей Суворов</li>
              <li>Анастасия Дахова</li>
              <li>Елизавета Якушкова</li>
              <li>Наталья Кузьмина</li>
              <li>Шеравган Махмудов</li>
            </ul>
            <p>Также спасибо руководству кафедры АТП за поддержку.</p>
          </div>
        </div>
        <button className="exit_button" onClick={() => setActive(!active)}>
          <img className="exit_logo" src={logo} alt="Exit logo"/>
        </button>
      </div>
    </div>
  );
};

export default ModalThanks;
