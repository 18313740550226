import React, {useContext} from 'react';
import {AuthContext} from '../../context/AuthContext';
import ErrorPage from '../../pages/errorPage/ErrorPage';

const PrivateRoute = ({children, allowedRoles}) => {
  const {userData} = useContext(AuthContext);
  if (userData.firstName === '') {
    return <ErrorPage errorCode={401} message={
      <>
        У вас нет прав доступа к этой странице.<br />
        Чтобы воспользоваться дополнительными возможностями, пожалуйста, авторизуйтесь.
      </>
    } />;
  }

  if (
    allowedRoles &&
    (!allowedRoles.includes(userData.userType) || (userData.userType === 'Teacher' && !userData.approved))
  ) {
    return <ErrorPage errorCode={403} message="Извините, у вас нет разрешения на доступ к этой странице." />;
  }

  return children;
};

export default PrivateRoute;
