import CryptoJS from 'crypto-js';

const calculateMd5 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const wordArray = CryptoJS.lib.WordArray.create(event.target.result);
      // eslint-disable-next-line new-cap
      const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex);
      resolve(md5Hash);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export default calculateMd5;
