import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import del from '../../assets/delete.svg';
import plus from '../../assets/plus.svg';
import {backendUrl} from '../../constant/AppConstant';
import {debounce} from 'lodash';

const CourseAssistants = ({selectedSupervisorId, selectedSeminarians, onSelectionChange}) => {
  const [assistants, setAssistants] = useState(['']);
  const [assistantInputValues, setAssistantInputValues] = useState(['']);
  const [showAssistantOptions, setShowAssistantOptions] = useState([]);
  const [filteredAssistants, setFilteredAssistants] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const assistantDropdownRefs = useRef([]);
  const [totalAssistants, setTotalAssistants] = useState([]);
  const [noMoreAssistantsMessage, setNoMoreAssistantsMessage] = useState(false);

  const fetchAssistants = useCallback(async (searchString = '') => {
    try {
      const response = await fetch(`${backendUrl}/teacher/getAssistantList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({searchString}),
      });
      if (response.ok) {
        const data = await response.json();
        const filterData = data.result.teachers.filter(
            (teacher) =>
              teacher.id !== selectedSupervisorId &&
              !selectedSeminarians.includes(teacher.id),
        );
        setFilteredAssistants(filterData);
        if (!searchString) {
          setTotalAssistants(filterData);
        }
        setHasFetched(true);
      } else {
        console.log('Error getting list of assistants:', response.statusText);
      }
    } catch (error) {
      console.log('Error executing request:', error);
    }
  }, [selectedSupervisorId, selectedSeminarians]);

  // eslint-disable-next-line
  const debouncedFetchAssistants = useCallback(debounce((searchString) => {
    fetchAssistants(searchString);
  }, 800), [fetchAssistants]);

  useEffect(() => {
    fetchAssistants();
    // eslint-disable-next-line
  }, [selectedSupervisorId, selectedSeminarians]);

  const handleAssistantClickOutside = useCallback((event) => {
    assistantDropdownRefs.current.forEach((dropdownRef, index) => {
      if (dropdownRef && !dropdownRef.contains(event.target)) {
        setShowAssistantOptions((prev) => {
          const updatedShowOptions = [...prev];
          updatedShowOptions[index] = false;
          return updatedShowOptions;
        });
      }
    });
  }, []);
  const remainingAssistants = useMemo(
      () =>
        totalAssistants.filter(
            (assistant) =>
              !assistants.includes(assistant.id) &&
              assistant.id !== selectedSupervisorId &&
              !selectedSeminarians.includes(assistant.id)
        ),
      [totalAssistants, assistants, selectedSupervisorId, selectedSeminarians]
  );

  const addAssistant = () => {
    if (remainingAssistants.length === 0) {
      setNoMoreAssistantsMessage(true);
      return;
    }
    if (assistants.some((assistant) => assistant === '')) {
      return;
    }
    setAssistants((prev) => [...prev, '']);
    setAssistantInputValues((prev) => [...prev, '']);
    setShowAssistantOptions((prev) => [...prev, false]);
    setNoMoreAssistantsMessage(false);
  };

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(assistants.filter((s) => s && s !== ''));
    }
  }, [assistants, onSelectionChange]);

  const removeAssistant = (index) => {
    const updatedAssistants = [...assistants];
    const updatedInputValues = [...assistantInputValues];
    const updatedShowOptions = [...showAssistantOptions];

    updatedAssistants.splice(index, 1);
    updatedInputValues.splice(index, 1);
    updatedShowOptions.splice(index, 1);

    if (updatedAssistants.length === 0) {
      setAssistants(['']);
      setAssistantInputValues(['']);
      setShowAssistantOptions([false]);
    } else {
      setAssistants(updatedAssistants);
      setAssistantInputValues(updatedInputValues);
      setShowAssistantOptions(updatedShowOptions);
    }
    setNoMoreAssistantsMessage(false);
  };

  const handleAssistantInputChange = (index, event) => {
    const value = event.target.value;
    const updatedInputValues = [...assistantInputValues];
    updatedInputValues[index] = value;
    setAssistantInputValues(updatedInputValues);

    const updatedShowOptions = [...showAssistantOptions];
    updatedShowOptions[index] = true;
    setShowAssistantOptions(updatedShowOptions);

    if (value.trim()) {
      debouncedFetchAssistants(value);
    } else {
      fetchAssistants();
    }
  };

  useEffect(() => {
    if (remainingAssistants.length > 0) {
      setNoMoreAssistantsMessage(false);
    }
  }, [remainingAssistants.length]);


  const handleAssistantInputFocus = (index) => {
    setShowAssistantOptions((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });

    if (!assistantInputValues[index].trim()) {
      fetchAssistants();
    }
  };

  const handleAssistantOptionClick = (index, selectedAssistant) => {
    const updatedAssistants = [...assistants];
    updatedAssistants[index] = selectedAssistant.id;
    setAssistants(updatedAssistants);

    const updatedInputValues = [...assistantInputValues];
    const patronymic = selectedAssistant.patronymic || '';
    updatedInputValues[index] =
      `${selectedAssistant.last_name} ${selectedAssistant.first_name} ${patronymic}\n${selectedAssistant.email}`;
    setAssistantInputValues(updatedInputValues);

    const updatedShowOptions = [...showAssistantOptions];
    updatedShowOptions[index] = false;
    setShowAssistantOptions(updatedShowOptions);
    fetchAssistants();
  };

  const filteredOptions = (index) => {
    return filteredAssistants.filter(
        (assistant) =>
          (!assistants.filter((id) => id !== '').includes(assistant.id) || assistants[index] === assistant.id) &&
          !selectedSeminarians.includes(assistant.id)
    );
  };

  useEffect(() => {
    const handleMousedown = (event) => {
      if (showAssistantOptions) {
        handleAssistantClickOutside(event);
      }
    };

    document.addEventListener('mousedown', handleMousedown);
    return () => {
      document.removeEventListener('mousedown', handleMousedown);
    };
    // eslint-disable-next-line
  }, [showAssistantOptions]);
  return (
    <div className="form-field">
      <div className="d-flex align-items-center">
        <h4>Ассистенты курса</h4>
      </div>
      {assistants.map((assistant, index) => (
        <div key={index} className="d-flex align-items-center">
          <div className="dropdown" ref={(el) => (assistantDropdownRefs.current[index] = el)}>
            <textarea
              value={assistantInputValues[index]}
              onFocus={() => handleAssistantInputFocus(index)}
              onChange={(e) => handleAssistantInputChange(index, e)}
              className="form-input form-select form-visitor"
              placeholder="Начните вводить ФИО или email"
              style={{
                whiteSpace: 'pre-line',
                resize: 'none',
                height: assistantInputValues[index] ? '45px' : '30px',
              }}
            />
            {showAssistantOptions[index] && (
              <div className="dropdown-menu">
                {filteredOptions(index).length > 0 ? (
                  filteredOptions(index).map((option) => (
                    <div
                      key={option.id}
                      className="dropdown-item"
                      onClick={() => handleAssistantOptionClick(index, option)}
                    >
                      <span>{option.last_name} {option.first_name} {option.patronymic || ''}</span>
                      <span style={{display: 'block', color: 'gray', fontSize: '12px'}}>
                        {option.email}
                      </span>
                    </div>
                  ))
                ) : (
                  hasFetched && <div className="dropdown-item">Ничего не найдено</div>
                )}
              </div>
            )}
          </div>
          {(assistants.length > 1 || assistants[index]) && (
            <button
              type="button"
              className="delete name-delete"
              onClick={() => removeAssistant(index)}
            >
              <img src={del} alt="Удалить" />
            </button>
          )}
        </div>
      ))}
      {noMoreAssistantsMessage && (
        <p style={{color: '#727272', margin: '10px 0', fontSize: '14px'}}>Больше нет ассистентов для выбора</p>
      )}
      <button
        type="button"
        className="form-plus"
        onClick={addAssistant}
      >
        <img src={plus} alt="Plus"/>
      </button>
    </div>
  );
};

export default CourseAssistants;
