import React from 'react';
import {Link} from 'react-router-dom';
import './errorPage.css';

const ErrorPage = ({errorCode, message}) => {
  return (
    <div className="container">
      <div className="error-page">
        <h1>Ошибка {errorCode} </h1>
        <p>
          {message}
        </p>
        <Link to="/" className="error__btn">
          Перейти на главную
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
