import React, {useCallback, useEffect, useRef, useState} from 'react';
import './configPage.css';
import '../registration/courseRegistration.css';
import {backendUrl} from '../../constant/AppConstant';
import download from '../../assets/download.svg';
import {debounce} from 'lodash';

const ConfigPage = () => {
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);

  const fetchCourses = async (searchString = '') => {
    try {
      const response = await fetch(`${backendUrl}/course/getCourseList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          searchString: searchString,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setFilteredOptions(data.result.courses);
        setHasFetched(true);
      } else {
        console.error('Error getting list of courses:', response.statusText);
      }
    } catch (error) {
      console.error('Error executing request:', error);
    }
  };

  // eslint-disable-next-line
  const debouncedFetchCourses = useCallback(debounce((searchString) => {
    fetchCourses(searchString);
  }, 500), []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.trim()) {
      debouncedFetchCourses(value);
    } else {
      fetchCourses();
    }
  };

  const handleInputFocus = () => {
    setShowOptions(true);
    if (!inputValue.trim()) {
      fetchCourses();
    }
  };

  const handleOptionClick = (course) => {
    setInputValue(course.name);
    setSelectedCourseId(course.id);
    setShowOptions(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadConfig = async () => {
    try {
      const response = await fetch(`${backendUrl}/config/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({courseId: selectedCourseId}),
      });
      if (response.ok) {
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'config.zip';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = fileNameMatch[1];
          }
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.log('Error downloading file: ', response.statusText);
      }
    } catch (error) {
      console.error('Error executing request: ', error);
    }
  };

  return (
    <div className="course">
      <div className="container">
        <div className="wrapper">
          <h2>Выгрузка конфигов</h2>
          <h3>Выберите учебный курс</h3>
          <div className="dropdown" ref={dropdownRef}>
            <input
              type="text"
              value={inputValue}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
              className="form-input form-select"
              placeholder="Начните вводить"
            />
            {showOptions && (
              <div className="dropdown-menu">
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((course, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleOptionClick(course)}
                    >
                      {course.name}
                    </div>
                  ))
                ) : (
                  hasFetched && (
                    <div className="dropdown-item">
                      Ничего не найдено
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <button
            className="form-register d-flex align-items-center"
            onClick={downloadConfig}
          >
            <p>Сгенерировать и скачать конфиги</p>
            <img src={download} alt="download button"/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfigPage;
